"use client";
import React from "react";
import { FormattedOdd, getPickAlignment, RecentPick } from "common";
import { LoadingCircle } from "../primitives/loading";
import { formatOdd } from "@/utils";
import { useOddsFormat } from "@/utils/providers/odds-format";
import assert from "assert";

export const getSportsbookEvents = async (picks: RecentPick[]): Promise<Record<
string,
{ title: string; url: string; logoUrl: string }[]
> | null> => {
  try {
    const gameIds = picks
      .map((pick: RecentPick) => pick.game.global_game_id)
      .join(",");
    const request = `${process.env.NEXT_PUBLIC_API_URL}/SportsbookEvents?gameIds=${gameIds}`;
    const res = await fetch(request);
    if (!res.ok) {
      throw new Error("Failed to fetch sportsbooks");
    }
    const data = await res.json();
    console.log("sportsbooks:", data);
    return data;
  } catch (error) {
    console.error("Error fetching sportsbooks:", error);
    return null;
  }
}

export default function PickSportsbooks({
  sportsbooks,
  activePick,
}: {
  sportsbooks: Record<
    string,
    { title: string; url: string; logoUrl: string }[]
  > | null;
  activePick: RecentPick;
}) {
  const { oddsFormat } = useOddsFormat();
  assert(activePick.type !== null);
  const alignment = getPickAlignment(activePick.type);
  const formattedOdd = formatOdd(oddsFormat, activePick?.odds[alignment]);
  if (!sportsbooks) {
    return (
      <div className="flex justify-center items-center w-full h-52">
        <LoadingCircle />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="text-secondary-300 text-title-lg-bold uppercase">
        Feeling Lucky? Bet for Real
      </div>

      <div className="gap-x-4 gap-y-3 grid grid-cols-3">
        {Array.isArray(sportsbooks[activePick.game.global_game_id]) &&
          (
            sportsbooks[activePick.game.global_game_id] as {
              title: string;
              url: string;
              logoUrl: string;
            }[]
          ).map((sportsbook, i) => {
            return (
              <PickSportsbookCard
                key={i}
                sportsbook={sportsbook}
                odd={formattedOdd!}
              />
            );
          })}
      </div>
    </div>
  );
}
function PickSportsbookCard({
  sportsbook,
  odd,
}: {
  sportsbook: any;
  odd: FormattedOdd;
}) {
  return (
    <div className="flex flex-col items-center gap-[10px] bg-surface-700 px-4 py-4 rounded-md">
      <img
        src={sportsbook.logoUrl}
        alt={sportsbook.title}
        className="w-auto h-10"
      />
      <div className="text-center">
        <div className="line-clamp-2 min-h-[28px] text-secondary-400 text-title-sm-medium">
          {sportsbook.title}
        </div>
        {/* <div className="text-secondary-400 text-title-sm-medium">
            <span className="text-secondary-300">{props.odd.value}</span>{" "}
            {props.odd.payout}
          </div> */}
      </div>

      <a
        className="btn btn-sm btn-filled-primary"
        href={sportsbook.url}
        target="_blank"
      >
        Bet Now
      </a>
    </div>
  );
}
