"use client";
import {
  Action,
  buildOdd,
  buildPaymentMethod,
  CheckoutIntent,
  GameAlignmentType,
  LineType,
  MarketplaceActionPayload,
  MarketplaceActionType,
  PaymentMethod,
  RecentPick as RecentPickType,
  Result,
} from "common";
import React, { createContext, useContext } from "react";
import RecentPick, { RecentPickState } from "../bettor/recent-pick";
import {
  loadStripe,
  StripeElementsOptionsMode,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import mlClient from "@/ml-client";
import {
  Elements,
  ExpressCheckoutElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useAuth } from "@clerk/clerk-react";
import Link from "next/link";
import Button from "../primitives/button";
import { PickSlipContextType, usePickSlip } from "./create-pick";
import assert from "assert";
import CreatedPick from "./created";
import PickSportsbooks, { getSportsbookEvents } from "./sportsbooks";
import { LoadingCircle } from "../primitives/loading";
import { useScoreboard } from "@/utils/providers/scoreboard";
import Icon from "../primitives/icon";
import { useOddsFormat } from "@/utils/providers/odds-format";
import { formatOdd } from "@/utils";
const stripePromise = loadStripe("pk_test_UcS8Fgxz53WmqZ6oiOPyJVPG");

export interface PickCheckoutContextType {
  checkoutIntent: CheckoutIntent | null;
  setCheckoutIntent: React.Dispatch<
    React.SetStateAction<CheckoutIntent | null>
  >;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  loading: PickCheckoutStep | null;
  setLoading: React.Dispatch<React.SetStateAction<PickCheckoutStep | null>>;
  step: PickCheckoutStep;
  setStep: React.Dispatch<React.SetStateAction<PickCheckoutStep>>;
  revealedPick: RecentPickType | null;
  setRevealedPick: React.Dispatch<React.SetStateAction<RecentPickType | null>>;
  paymentMethod: PaymentMethod | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | null>>;
}
export const PickCheckoutContext =
  createContext<PickCheckoutContextType | null>(null);

export const usePickCheckout = () => {
  const context = useContext(PickCheckoutContext);
  if (!context) {
    throw new Error(
      "usePickCheckout must be used within a PickCheckoutProvider"
    );
  }
  return context;
};

const localCheckoutIntentKey = "checkoutIntent";

export const getCheckoutIntent = (pickId: number): CheckoutIntent | null => {
  const stored = localStorage.getItem(localCheckoutIntentKey);
  if (stored) {
    const parsedStored: CheckoutIntent = JSON.parse(stored);
    if (parsedStored.metadata.pick.id === pickId) {
      return parsedStored;
    } else {
      return null;
    }
  }
  return null;
};

export const removeCheckoutIntent = () => {
  localStorage.removeItem(localCheckoutIntentKey);
};

enum PickCheckoutStep {
  Overview,
  Payment,
  Review,
  Reveal,
}

export default function PickCheckout() {
  const slip = usePickSlip();
  const [checkoutIntent, setCheckoutIntent] =
    React.useState<CheckoutIntent | null>(null);
  const [loading, setLoading] = React.useState<PickCheckoutStep | null>(
    PickCheckoutStep.Overview
  );
  const [step, setStep] = React.useState<PickCheckoutStep>(
    PickCheckoutStep.Overview
  );
  const [error, setError] = React.useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [revealedPick, setRevealedPick] = React.useState<RecentPickType | null>(
    null
  );
  // Change button whenever the step or loading state changes.
  React.useEffect(() => {
    if (loading !== null) {
      slip.setButton(
        <Button className="!justify-center min-w-[146px]" disabled={true}>
          Loading...
        </Button>
      );
    }
  }, [loading]);

  assert(slip?.checkoutPick, "Pick isn't for sale.");

  const options: StripeElementsOptionsMode = {
    mode: "payment",
    amount: (checkoutIntent?.amount || 0) + (checkoutIntent?.tax || 0),
    currency: "usd",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "flat",
    },
  };

  const render = (): React.ReactNode => {
    switch (step) {
      case PickCheckoutStep.Overview:
        return <Overview />;
      case PickCheckoutStep.Payment:
        return (
          <Elements stripe={stripePromise} options={options}>
            <Payment />
          </Elements>
        );
      case PickCheckoutStep.Review:
        return <Review />;
      case PickCheckoutStep.Reveal:
        return <Reveal />;
      default:
        return <>Something rather unexpected happened. Contact an admin.</>;
    }
  };
  return (
    <PickCheckoutContext.Provider
      value={{
        checkoutIntent,
        setCheckoutIntent,
        error,
        setError,
        loading,
        setLoading,
        step,
        setStep,
        paymentMethod,
        setPaymentMethod,
        revealedPick,
        setRevealedPick,
      }}
    >
      <Steps />
      {error}
      {render()}
    </PickCheckoutContext.Provider>
  );
}

/** Step 1 - Overview
 * Gets or creates a checkout intent.
 */
function Overview() {
  const checkout = usePickCheckout();
  const slip = usePickSlip();
  const { getToken } = useAuth();
  assert(slip?.checkoutPick, "Pick isn't for sale.");

  /** Any time the pick id changes, get the checkout intent.
   * First will attempt to get the intent from localStorage and will fallback to the server.
   */
  const createCheckoutIntent = async () => {
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    assert(slip?.checkoutPick.id, "Pick is missing id.");
    try {
      const intent = getCheckoutIntent(slip.checkoutPick.id);
      if (intent) {
        checkout.setRevealedPick({
          ...slip.checkoutPick,
          bettor: intent.metadata.pick.bettor,
          created_at: intent.metadata.pick.created_at,
        });
        checkout.setCheckoutIntent(intent);
        checkout.setLoading(null);
        slip.setPrice(((intent.amount + intent.tax) / 100).toFixed(2));
        return;
      }
      checkout.setLoading(PickCheckoutStep.Overview);

      const token = await getToken();
      const action: Action<MarketplaceActionType, MarketplaceActionPayload> = {
        type: MarketplaceActionType.CreatePaymentIntent,
        data: {
          pick_id: slip.checkoutPick.id,
          bettor_id: slip.checkoutPick.bettor_id,
        },
      };
      const result = await mlClient.post(
        "/marketplace/checkout-intent",
        action,
        token,
        {
          validateStatus: () => true,
        }
      );
      if (!result.data.ok) {
        checkout.setError(result.data.message);
      }
      if (result.data.data) {
        const intent = result.data.data;
        checkout.setCheckoutIntent(intent);
        checkout.setRevealedPick({
          ...slip.checkoutPick,
          bettor: intent.metadata.pick.bettor,
          created_at: intent.metadata.pick.created_at,
        });
        localStorage.setItem(localCheckoutIntentKey, JSON.stringify(intent));
        slip.setPrice(((intent.amount + intent.tax) / 100).toFixed(2));
      }
    } catch (err) {
      console.log("Error in createPaymentIntent:", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  React.useEffect(() => {
    if (!checkout.checkoutIntent) {
      createCheckoutIntent();
    }
  }, [checkout.checkoutIntent, slip.checkoutPick.id]);

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => {
          checkout.setStep(PickCheckoutStep.Payment);
        }}
      >
        Continue
      </Button>
    );
  }, [checkout.loading, checkout.step]);

  if (checkout.loading === PickCheckoutStep.Overview) {
    return <>Loading Overview...</>;
  }

  assert(checkout.revealedPick);
  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      <RecentPick
        pick={checkout.revealedPick}
        remove={slip.removeCheckoutPick}
        state={RecentPickState.Checkout}
      />
      {process.env.NEXT_PUBLIC_STATE !== "testing" && <OddComparison />}
      <Total />
      <div className="text-secondary text-center text-body-sm leading-6 max-w-[280px] mx-auto">
        By completing your purchase, you agree to our{" "}
        <Link href="/terms-conditions" className="underline">
          Terms & Conditions
        </Link>{" "}
        and{" "}
        <Link href="/privacy-policy" className="underline">
          Privacy Policy.
        </Link>
      </div>
    </div>
  );
}

/** Step 2 - Payment
 * Provides a list of saved payment methods and allows you add new ones.
 * Once finished, collects your details to attach it to the intent on the server.
 */
function Payment() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  const stripe = useStripe();
  const elements = useElements();
  const { getToken } = useAuth();
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>(
    checkout.checkoutIntent?.payment_methods || []
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<PaymentMethod | null>(() => {
      const selectedId = checkout.checkoutIntent?.selected_payment_method_id;
      let foundMethod = null;
      if (selectedId) {
        foundMethod = paymentMethods.find((pm) => pm.id === selectedId);
      }
      return (
        foundMethod ?? (paymentMethods.length > 0 ? paymentMethods[0] : null)
      );
    });

  const [expressCheckoutReady, setExpressCheckoutReady] =
    React.useState<boolean>(false);
  const [paymentFormComplete, setPaymentFormComplete] =
    React.useState<boolean>(false);

  const loading = checkout.loading === PickCheckoutStep.Payment;

  console.log(
    "bettors payment methods saved:",
    checkout.checkoutIntent?.payment_methods
  );
  console.log("selected payment method:", selectedPaymentMethod);

  const updateCheckoutIntent = async () => {
    console.log("updating checkout intent");
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    let paymentMethodId: string | null = selectedPaymentMethod?.id ?? null;
    console.log("payment method when updating intent:", paymentMethodId);
    try {
      if (!paymentMethodId) {
        if (!stripe || !elements) return;
        const { error: submitError } = await elements.submit();
        if (submitError) {
          console.error("Form validation failed:", submitError.message);
          checkout.setError("Payment element failed to validate.");
          return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements,
        });

        if (error) {
          console.error("Error creating payment method:", error.message);
          checkout.setError("Error creating payment method.");
          return;
        }
        paymentMethodId = paymentMethod.id;
        checkout.setPaymentMethod(buildPaymentMethod(paymentMethod));
        console.log("New payment method created:", paymentMethod);
      }
      checkout.setLoading(PickCheckoutStep.Review);
      const token = await getToken();
      const action = {
        type: MarketplaceActionType.ConfirmPayment,
        data: {
          pick_id: slip.checkoutPick.id,
          selected_payment_method_id: paymentMethodId,
        },
      };
      const result = await mlClient.put(
        "/marketplace/checkout-intent",
        action,
        token
      );
      if (result.data.ok && result.data.code === 201) {
        const newIntent = checkout.checkoutIntent
          ? {
              ...checkout.checkoutIntent,
              selected_payment_method_id: paymentMethodId,
            }
          : checkout.checkoutIntent;
        checkout.setCheckoutIntent(newIntent);
        localStorage.setItem(localCheckoutIntentKey, JSON.stringify(newIntent));
        checkout.setPaymentMethod(selectedPaymentMethod);
        checkout.setStep(PickCheckoutStep.Review);
      }
    } catch (err) {
      console.error("Error updating checkout intent", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => updateCheckoutIntent()}
        disabled={!paymentFormComplete && !selectedPaymentMethod}
      >
        Continue
      </Button>
    );
  }, [checkout.loading, checkout.step, paymentFormComplete]);

  assert(slip?.checkoutPick, "Pick isn't for sale.");

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "auto",
  };

  if (loading) {
    return <>Loading Payment...</>;
  }

  const onConfirm = async () => {
    return;
  };

  if (!stripe || !elements) {
    return;
  }

  const handlePaymentElementChange = (event: any) => {
    setPaymentFormComplete(event.complete);
  };

  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      <ExpressCheckoutElement
        onConfirm={onConfirm}
        onReady={() => setExpressCheckoutReady(true)}
        onLoadError={(e) => checkout.setError(e.error.message || "")}
      />
      {!expressCheckoutReady && <div>Loading Express Checkout...</div>}
      {paymentMethods.map((pm) => (
        <>
          {pm.id}
          xxxx-xxxx-xxxx-{pm.last4} {pm.exp_month} {pm.exp_year} {pm.brand}
        </>
      ))}
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onLoadError={(e) => checkout.setError(e.error.message || "")}
        onChange={handlePaymentElementChange}
      />
    </div>
  );
}

/** Step 3 - Review
 * Shows a summary of the payment to be made
 * along with the selected payment method.
 */
function Review() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  const { getToken } = useAuth();
  const loading = checkout.loading === PickCheckoutStep.Review;

  const confirmPayment = async () => {
    assert(slip?.checkoutPick, "Pick isn't for sale.");
    try {
      checkout.setLoading(PickCheckoutStep.Reveal);
      const token = await getToken();
      const action = {
        type: MarketplaceActionType.ConfirmPayment,
        data: {
          pick_id: slip.checkoutPick.id,
          bettor_id: slip.checkoutPick.bettor_id,
        },
      };
      const result = await mlClient.post(
        "/marketplace/confirm-payment",
        action,
        token
      );
      if (result.data.ok && result.data.code === 201) {
        checkout.setCheckoutIntent(result.data);
        console.log("checkout intent data", result.data);
        const newRevealedPick = checkout.revealedPick
          ? { ...checkout.revealedPick, type: result.data.revealed_pick }
          : null;
        checkout.setRevealedPick(newRevealedPick);
        localStorage.removeItem("checkoutPick");
        if (newRevealedPick) {
          const sportsbookEvents = await getSportsbookEvents([newRevealedPick]);
          slip.setSportsbooks(sportsbookEvents);
        }
        checkout.setStep(PickCheckoutStep.Reveal);
      }
    } catch (err) {
      console.error("Error confirming payment.", err);
      checkout.setError("Something unexpected happened. Contact an admin.");
    } finally {
      checkout.setLoading(null);
    }
  };

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button
        className="!justify-center min-w-[146px]"
        onClick={() => confirmPayment()}
      >
        Confirm & Pay
      </Button>
    );
  }, [checkout.loading, checkout.step]);

  assert(slip?.checkoutPick, "Pick isn't for sale.");
  if (loading) {
    return <>Loading Review...</>;
  }
  assert(checkout.revealedPick);
  assert(checkout.paymentMethod);
  return (
    <div className="flex flex-col gap-4 bg-surface-700 p-4 rounded-md">
      <RecentPick
        pick={checkout.revealedPick}
        remove={slip.removeCheckoutPick}
        state={RecentPickState.Checkout}
      />
      {process.env.NEXT_PUBLIC_STATE !== "testing" && <OddComparison />}
      {checkout.paymentMethod.id}
      xxxx-xxxx-xxxx-{checkout.paymentMethod.last4}{" "}
      {checkout.paymentMethod.exp_month} {checkout.paymentMethod.exp_year}{" "}
      {checkout.paymentMethod.brand}
      <Total />
    </div>
  );
}

function Reveal() {
  const slip = usePickSlip();
  const checkout = usePickCheckout();
  assert(slip.checkoutPick, "Must have a pick in cart.");
  const loading = checkout.loading === PickCheckoutStep.Reveal;

  // On render, change the button of the pick slip.
  React.useEffect(() => {
    slip.setButton(
      <Button className="!justify-center min-w-[146px]">Place Bet</Button>
    );
  }, [checkout.loading, checkout.step]);

  console.log("revealed", checkout.revealedPick);
  if (loading) {
    return (
      <>
        Get ready to see your pick!
        <LoadingCircle />
      </>
    );
  }
  assert(checkout.revealedPick);

  return (
    <div className="gap-6 grid grid-rows-[auto_auto] px-2 sm:px-3 pb-4 overflow-y-auto grow mt-2">
      <div className="flex flex-col gap-4">
        <CreatedPick pick={checkout.revealedPick} />
      </div>
      <PickSportsbooks
        sportsbooks={slip.sportsbooks}
        activePick={checkout.revealedPick}
      />
    </div>
  );
}

function Steps() {
  const checkout = usePickCheckout();
  return (
    <div className="flex flex-row items-center gap-4 px-4 pb-4">
      <button
        className={`${
          checkout.step >= PickCheckoutStep.Overview
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (checkout.step >= PickCheckoutStep.Overview) {
            checkout.setStep(PickCheckoutStep.Overview);
          }
        }}
      >
        Overview
      </button>
      <div
        className={`${
          checkout.step >= PickCheckoutStep.Overview
            ? "bg-secondary-200"
            : "bg-secondary-700"
        } transition-all ease-in-out duration-200 w-full h-px`}
      ></div>
      <button
        className={`${
          checkout.step >= PickCheckoutStep.Payment
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (checkout.step >= PickCheckoutStep.Overview) {
            checkout.setStep(PickCheckoutStep.Payment);
          }
        }}
      >
        Payment
      </button>
      <div
        className={`${
          checkout.step >= PickCheckoutStep.Payment
            ? "bg-secondary-200"
            : "bg-secondary-700"
        } transition-all ease-in-out duration-200 w-full h-px`}
      ></div>
      <span
        className={`${
          checkout.step >= PickCheckoutStep.Review
            ? "text-secondary-200"
            : "text-secondary-500"
        } transition-all ease-in-out duration-200 text-title-lg-bold uppercase`}
        onClick={() => {
          if (
            checkout.step >= PickCheckoutStep.Review ||
            checkout.paymentMethod
          ) {
            checkout.setStep(PickCheckoutStep.Review);
          }
        }}
      >
        Review
      </span>
    </div>
  );
}

function Total() {
  const checkout = usePickCheckout();
  const subtotal = ((checkout.checkoutIntent?.amount || 100) / 100).toFixed(2);
  const tax = (checkout.checkoutIntent?.tax || 0).toFixed(2);
  const total = (
    (checkout.checkoutIntent?.amount || 100) / 100 +
    (checkout.checkoutIntent?.tax || 0)
  ).toFixed(2);
  const currency = "USD";
  return (
    <div className="w-full bg-surface-500 rounded-md flex flex-col p-3 gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row text-title-sm justify-between items-center">
          <span>Subtotal</span>
          <span>${subtotal}</span>
        </div>
        <div className="flex flex-row text-title-sm justify-between items-center">
          <span>Tax</span>
          <span>${tax}</span>
        </div>
      </div>
      <div className="flex flex-row text-title-lg-bold uppercase justify-between items-center">
        <span>Total</span>
        <span>
          {currency} ${total}
        </span>
      </div>
    </div>
  );
}

function OddComparison() {
  const slip = usePickSlip();
  const { games } = useScoreboard();
  const { oddsFormat } = useOddsFormat();
  assert(slip.checkoutPick);

  let sameOdds: boolean = false;
  const game = games?.find(
    (game) => game.id === slip.checkoutPick?.game.global_game_id
  );
  assert(
    game,
    "Couldn't find this game on the scoreboard. This pick shouldn't be for sale."
  );
  const awayOdds = buildOdd(
    slip.checkoutPick.line,
    GameAlignmentType.Away,
    game.odds
  );
  const homeOdds = buildOdd(
    slip.checkoutPick.line,
    GameAlignmentType.Home,
    game.odds
  );
  const formattedAwayOdds = formatOdd(oddsFormat, awayOdds);
  const formattedHomeOdds = formatOdd(oddsFormat, homeOdds);
  switch (slip.checkoutPick.line) {
    case LineType.Moneyline:
      if (
        slip.checkoutPick.odds[0].payout === game.odds?.awayMoneyline &&
        slip.checkoutPick.odds[1].payout === game.odds?.homeMoneyline
      ) {
        sameOdds = true;
      }
      break;
    case LineType.Spread:
      if (
        slip.checkoutPick.odds[0].value === game.odds?.awaySpread &&
        slip.checkoutPick.odds[1].value === game.odds?.homeSpread &&
        slip.checkoutPick.odds[0].payout === game.odds?.awaySpreadPayout &&
        slip.checkoutPick.odds[1].payout === game.odds?.homeSpreadPayout
      ) {
        sameOdds = true;
      }
      break;
    case LineType.Total:
      if (
        slip.checkoutPick.odds[0].value === game.odds?.overUnder &&
        slip.checkoutPick.odds[0].payout === game.odds?.overPayout &&
        slip.checkoutPick.odds[1].payout === game.odds?.underPayout
      ) {
        sameOdds = true;
      }
      break;
  }
  return (
    <div className="bg-secondary-300 rounded-md p-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-y-1">
          <div className="text-label-lg-bold uppercase text-white">
            Current Game Odds
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <Icon
              variant="circle"
              name="checkmark"
              size="xs"
              className={`${
                sameOdds ? "bg-primary-300" : "bg-error-400"
              } fill-surface-700`}
            />
            <div className="text-label-sm-medium text-secondary">
              Odds are currently {sameOdds ? "the same." : "different"}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
            <span className="text-surface-700 uppercase min-w-[24px]">
              {slip.checkoutPick.game.teams[0].abbreviation}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedAwayOdds?.value}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedAwayOdds?.payout}
            </span>
          </div>
          <div className="flex flex-row gap-x-3 justify-between items-center text-label-lg-bold">
            <span className="text-surface-700 uppercase min-w-[24px]">
              {slip.checkoutPick.game.teams[1].abbreviation}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedHomeOdds?.value}
            </span>
            <span
              className={`${sameOdds ? "text-primary-500" : "text-error-400"}`}
            >
              {formattedHomeOdds?.payout}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
