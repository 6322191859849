import { formatOdd } from "@/utils";
import { useOddsFormat } from "@/utils/providers/odds-format";
import {
  BettorDetailsModel,
  formatLineType,
  GameAlignmentType,
  getPickAlignment,
  LineType,
  Odd2,
  PickType,
  RecentPick,
  TeamBasic,
} from "common";
import Card from "../primitives/card";
import GameTimestamp from "../game/timestamp";
import TeamLogo from "../team/logo";
import { createProfileSlug } from "@/utils/url";
import Link from "next/link";
import Icon from "../primitives/icon";
import { useBettor } from "@/utils/providers/bettor";
import assert from "assert";

export default function CreatedPick({ pick }: { pick: RecentPick }) {
  const { bettor } = useBettor();
  const { oddsFormat } = useOddsFormat();

  const awayTeam = pick.game.teams[0];

  const homeTeam = pick.game.teams[1];
  assert(pick.type !== null, "Pick failed to be revealed.");
  const alignment = getPickAlignment(pick.type);
  const pickedTeam = alignment === GameAlignmentType.Away ? awayTeam : homeTeam;
  const odds = pick.odds[alignment];

  const formattedOdd = formatOdd(oddsFormat, odds)!;

  return (
    <div className="relative flex flex-col gap-4 bg-secondary-300 px-3 sm:px-5 py-6 rounded-xl text-surface-700 overflow-hidden">
      <div className="z-10 absolute inset-0">
        <video
          src="/blob-430x430.mp4"
          playsInline
          muted
          autoPlay
          loop
          className="w-full h-full object-cover object-bottom"
        ></video>
      </div>

      <div className="relative z-20 flex flex-col gap-6">
        <div className="flex justify-start items-center">
          <div className="line-clamp-1 text-surface-700 text-title-lg-bold uppercase overflow-ellipsis">
            {awayTeam.name} @ {homeTeam.name}
          </div>
          {/* <button className="flex items-center gap-1 text-body-sm-medium text-secondary-500 uppercase">
              Share <Icon name="share" className="fill-secondary-500" size="sm" />
            </button> */}
        </div>

        {/* <div className="flex flex-col items-center gap-2">
              <div className="flex justify-center items-center bg-[#0A9B00] bg-opacity-20 rounded-full w-20 h-20">
                <svg
                  width="43"
                  height="31"
                  viewBox="0 0 43 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.5 2L14.5 28L2.5 16"
                    stroke="#009B10"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="text-headline-lg text-surface-700 uppercase">
                Pick Created!
              </div>
            </div> */}
        <Card
          custom
          dark
          className="relative after:z-[1] before:absolute after:absolute before:-inset-[1px] after:inset-0 gap-5 after:bg-secondary-300 before:bg-gradient-to-br before:from-primary-500 before:to-[#40E3ED] before:rounded-lg after:rounded-lg overflow-visible"
        >
          <div className="relative px-0 xs:px-2 sm:px-3 pt-4 pb-5 rounded-lg overflow-hidden">
            <div className="z-10 absolute inset-0">
              <video
                src="/glowing-lines.mp4"
                playsInline
                autoPlay
                muted
                loop
                className="w-full h-full object-cover"
              ></video>
            </div>
            <div className="relative z-20 flex flex-col">
              <div className="flex flex-col justify-center items-center gap-[2px]">
                <div className="text-headline-medium text-light capitalize">
                  {formatLineType(pick.line, false, true)}
                </div>

                <GameTimestamp
                  type="full"
                  className="text-label-lg text-secondary-500"
                  dateTime={pick.game.date_time_utc}
                  platform={pick.game.platform}
                />
              </div>
              <div className="relative z-[1] flex justify-between items-start gap-2">
                <Team team={awayTeam} side="left" />
                <div className="flex justify-center items-center pt-5 min-w-[100px]">
                  <Card
                    custom
                    className="relative gap-1 py-2 !rounded-md w-full min-w-[68px] max-w-[94px] h-[60px] overflow-visible pickBounce-animation"
                  >
                    <PickStars />
                    {pick.line === LineType.Total ? (
                      <div className="flex justify-center items-center gap-1 pt-[2px] pb-2">
                        <Icon
                          name={
                            pick.type === PickType.Over
                              ? "arrow-up"
                              : "arrow-down"
                          }
                          size="xs"
                          className={
                            pick.type === PickType.Over
                              ? "fill-primary-500"
                              : "fill-hot"
                          }
                        />
                        <div className="text-dark text-label-lg-bold uppercase">
                          {formatLineType(pick.line, false, true)}
                        </div>
                      </div>
                    ) : (
                      <div className="relative flex justify-center items-center gap-2">
                        <TeamLogo team={pickedTeam} size="xs" className="" />
                        <div className="flex flex-col">
                          <div className="text-dark text-title-lg-medium">
                            {pickedTeam.abbreviation}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-dark text-label-odds">
                        {pick.line === LineType.Moneyline
                          ? formattedOdd.payout
                          : formattedOdd.value}
                      </div>
                      {formattedOdd.payout && (
                        <div className="text-label-bold text-secondary-400">
                          {pick.line === LineType.Moneyline
                            ? undefined
                            : formattedOdd.payout}
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
                <Team team={homeTeam} side="right" />
              </div>
            </div>
          </div>
        </Card>
        <div className="flex justify-end">
          <Link
            href={createProfileSlug(bettor?.details?.display_name || "")}
            prefetch={false}
            className="text-label-lg-bold text-secondary-500 underline uppercase"
          >
            View all my picks
          </Link>
        </div>
      </div>
    </div>
  );
}

function Team({ team, side }: { team: TeamBasic; side: "left" | "right" }) {
  return (
    <div className="relative flex flex-col justify-center items-center gap-2 p-2">
      <TeamLogo
        team={team}
        size="lg"
        custom
        className={`absolute object-contain w-[180px] h-[180px] bottom-0 ${
          side === "left" ? "-right-8" : "-left-8"
        } opacity-10 max-w-none pointer-events-none`}
      />
      <TeamLogo custom team={team} className="z-[1] w-[54px] h-[54px]" />

      <div className="text-title-odds text-white">{team.abbreviation}</div>
    </div>
  );
}

function PickStars() {
  return (
    <svg
      width="122"
      height="99"
      viewBox="0 0 122 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="-top-4 -left-4 z-20 absolute pointer-events-none"
    >
      <g opacity="0.7" filter="url(#filter0_d_9501_330017)">
        <path
          d="M50.5148 83.0557C50.7177 83.4506 51.2823 83.4506 51.4852 83.0557L52.9564 80.1923C53.0085 80.091 53.091 80.0085 53.1923 79.9564L56.0557 78.4852C56.4506 78.2823 56.4506 77.7177 56.0557 77.5148L53.1923 76.0436C53.091 75.9915 53.0085 75.909 52.9564 75.8077L51.4852 72.9443C51.2823 72.5494 50.7177 72.5494 50.5148 72.9443L49.0436 75.8077C48.9915 75.909 48.909 75.9915 48.8077 76.0436L45.9443 77.5148C45.5494 77.7177 45.5494 78.2823 45.9443 78.4852L48.8077 79.9564C48.909 80.0085 48.9915 80.091 49.0436 80.1923L50.5148 83.0557Z"
          fill="#B1ED40"
        />
      </g>
      <g filter="url(#filter1_d_9501_330017)">
        <path
          d="M38.7107 86.8495L40 89.3589L41.2893 86.8495C41.413 86.6089 41.6089 86.413 41.8495 86.2893L44.3589 85L41.8495 83.7107C41.6089 83.587 41.413 83.3911 41.2893 83.1505L40 80.6411L38.7107 83.1505C38.587 83.3911 38.3911 83.587 38.1505 83.7107L35.6411 85L38.1505 86.2893C38.3911 86.413 38.587 86.6089 38.7107 86.8495Z"
          stroke="#B1ED40"
          strokeWidth="1.5"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M111.711 75.1505L113 72.6411L114.289 75.1505C114.413 75.3911 114.609 75.587 114.85 75.7107L117.359 77L114.85 78.2893C114.609 78.413 114.413 78.6089 114.289 78.8495L113 81.3589L111.711 78.8495C111.587 78.6089 111.391 78.413 111.15 78.2893L108.641 77L111.15 75.7107C111.391 75.587 111.587 75.3911 111.711 75.1505Z"
        stroke="#B1ED40"
        strokeWidth="1.5"
      />
      <path
        opacity="0.8"
        d="M28.7107 11.1505L30 8.64111L31.2893 11.1505C31.413 11.3911 31.6089 11.587 31.8495 11.7107L34.3589 13L31.8495 14.2893C31.6089 14.413 31.413 14.6089 31.2893 14.8495L30 17.3589L28.7107 14.8495C28.587 14.6089 28.3911 14.413 28.1505 14.2893L25.6411 13L28.1505 11.7107C28.3911 11.587 28.587 11.3911 28.7107 11.1505Z"
        stroke="#B1ED40"
        strokeWidth="1.5"
      />
      <defs>
        <filter
          id="filter0_d_9501_330017"
          x="41.6484"
          y="72.6484"
          width="18.7031"
          height="18.7031"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9501_330017"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9501_330017"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_9501_330017"
          x="30.6484"
          y="79.6484"
          width="18.7031"
          height="18.7031"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9501_330017"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9501_330017"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
