import React from "react";
import styles from './status-chip.module.css';
import { GameStatus, OddOutcome, formatOddOutcome } from "common";

export default function OddsStatusChip({dark, outcome, exception}: {dark?: boolean, outcome: OddOutcome | null, exception?: GameStatus}) {
  const formattedOutcome: string = exception ? exception as string : outcome !== null ? formatOddOutcome(outcome) : "";
  return (
    <div className={styles.root} data-status={formattedOutcome} data-dark={`${dark}`}>
      <div>{formattedOutcome}</div>
    </div>
  );
}
